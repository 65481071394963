<template>
  <div v-if="mapsConsentStatus" class="editorial-map" :class="overlayStyleClass">
    <GoogleMap
      class="editorial-map__gmap"
      ref="gmap"
      :current-retailers="filteredRetailers"
      :current-showrooms="filteredShowrooms"
      :google-maps-api-key="googleMapsApiKey"
      :current-location="currentLocation"
      @markerClicked="OnMarkerClicked"
      @showroomClicked="OnShowroomClicked"
      :is-mobile="isMobile"
    />

    <MapSearchLocation class="editorial-map__searchlocation" />
    <div class="editorial-map__results" :class="{ fixed: resultsFixed }">
      <div class="editorial-map__button-wrapper top">
        <IconArrowLeft class="editorial-map__button-arrow" @click="toggleOverlay" />
      </div>
      <div class="editorial-map__results__content" v-if="resultsVisible">
        <div class="editorial-map-results-wrapper">
          <div class="editorial-map-results-intro">
            <h3 class="result__headline" v-if="currentLocation.isShowroom">
              {{ $t('editorial-map-overlay-headline-country') }}
            </h3>
            <h3 v-else class="result__headline">
              {{ $t('editorial-map-overlay-headline-city') }} {{ location.name }}
            </h3>
            <div class="result__copy">{{ $t('editorial-map-overlay-copy') }}</div>
          </div>
          <ResultListShowrooms
            v-if="showShowrooms"
            :results="filteredShowrooms"
            :is-mobile="isMobile"
          />
          <ResultListRetailers
            v-else
            :results="filteredRetailers"
            @markerClicked="OnMarkerClicked"
            :scroll-to-retailer="scrollToRetailer"
            :is-mobile="isMobile"
          />
        </div>
      </div>
      <div class="editorial-map__results__opener" />
      <div class="editorial-map__button-wrapper aside">
        <IconArrowLeft class="editorial-map__button-arrow" @click="toggleOverlay" />
      </div>
    </div>
  </div>
  <div class="editorial-map__no-map" v-else>
    <template v-if="mapsConsentStatus === false">
      <div class="editorial-map__no-map-content">
        <IconCross class="editorial-map__no-map-icon" />
        <p class="editorial-map__no-map-text">{{ noConsentText }}</p>
      </div>
      <div class="editorial-map__no-map-content">
        <input
          type="checkbox"
          id="editorial-map__checkbox"
          @click="toggleGoogleMaps"
          class="editorial-map__checkbox"
        />
        <label for="editorial-map__checkbox" class="editorial-map__no-map-text">
          {{ $t('editorial-map-google-maps-checkbox') }}
        </label>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import MapSearchLocation from './MapSearchLocation';
import ResultListRetailers from './ResultListRetailers';
import ResultListShowrooms from './ResultListShowrooms';
import GoogleMap from './GoogleMap';
import queryRetailers from '../../constants/graphql/EditorialMap.apollo.graphql';
import { resolveQuery } from './../helper';
import smoothscroll from 'smoothscroll-polyfill';

import IconCross from '../../assets/icons/IconCross.svg?inline';
import IconArrowLeft from '../../assets/icons/IconArrowLeft.svg?inline';
import { bp1024 } from '../../styles/variables.scss';

const debug = false;

const layerStates = {
  FULL: 'full',
  MIDDLE: 'middle',
  CLOSED: 'closed',
};

export default {
  name: 'EditorialMap',
  components: {
    ResultListRetailers,
    ResultListShowrooms,
    IconArrowLeft,
    MapSearchLocation,
    GoogleMap,
    IconCross,
  },
  inject: ['apollo'],
  data() {
    return {
      overlayState: layerStates.MIDDLE,
      initialShowrooms: [],
      initialCountries: [],
      initialRetailers: [],
      retailersNearLocation: [],
      googleMapsApiKey: [],
      resolvedQuery: undefined,
      scrollToRetailer: false,
      showShowrooms: true,
      mapsConsent: undefined,
      mapsConsentChecked: undefined,
      resultsFixed: false,
      resultsVisible: true,
      zoomIn: false,
      touchFinished: false,
      resizedFinished: false,
      touchStart: false,
      mobile: false,
    };
  },
  computed: {
    ...mapGetters(['getUserCentricsId', 'getUserCentricsGoogleMapsServiceId']),
    ...mapGetters('jss', ['currentRouteLanguage']),
    ...mapGetters('jss', ['isConnected']),
    ...mapGetters('map', {
      currentLocation: 'currentLocation',
      currentSearchModel: 'getSearchModel',
      fetchNewData: 'getFetchNewData',
      getFilteredShowrooms: 'getFilteredShowrooms',
    }),
    filteredRetailers() {
      return this.retailersNearLocation;
    },
    filteredShowrooms() {
      return this.getFilteredShowrooms;
    },

    noConsentText() {
      return this.$t('editorial-map-no-consent-text');
    },
    location() {
      return this.currentLocation;
    },
    overlayStyleClass() {
      return this.overlayState;
    },
    mapsConsentStatus() {
      return this.mapsConsent;
    },
    getTouchStart() {
      return this.touchStart;
    },
    getTouchFinished() {
      return this.touchFinished;
    },
  },
  methods: {
    ...mapActions('map', {
      setSearchLocationVisibility: 'setSearchLocationVisibility',
      setCountries: 'setCountries',
      setShowrooms: 'setShowrooms',
      setGoogleMapsApiKey: 'setGoogleMapsApiKey',
      setFetchNewData: 'setFetchNewData',
    }),
    checkIfIsMobile() {
      if (typeof window !== 'undefined') {
        return (window.innerWidth || screen?.width) <= parseInt(bp1024, 10) ? true : false;
      } else {
        return false;
      }
    },
    OnShowroomClicked() {
      this.showShowrooms = true;
      this.overlayState = layerStates.MIDDLE;
      this.scrollToTop();
    },
    OnMarkerClicked(id) {
      this.selectRetailer(id, false);
    },
    async retrieveNewRetailerData() {
      try {
        if (debug) {
          console.log('fetchNewData with this.countryCode ' + this.currentSearchModel.country.code);
        }
        const newData = await this.apollo.query({
          query: this.resolvedQuery,
          fetchPolicy: 'network-only',
          variables: {
            lat: this.currentLocation?.latitude,
            lng: this.currentLocation?.longitude,
            country: this.currentSearchModel.country.code,
            take: this.currentSearchModel.hits,
          },
        });

        return newData.data?.retailer?.results?.items;
      } catch (e) {
        console.log('GraphQL error (query EditorialMap) in EditorialMap (NONEO-16)', e.message);
      }
    },
    async fetchRetailers() {
      if (this.isConnected) {
        this.retailersNearLocation = await this.retrieveNewRetailerData();
        this.setFetchNewData(false);
      }
    },

    toggleOverlay() {
      if (this.isMobile) {
        switch (this.overlayState) {
          case layerStates.FULL:
            this.overlayState = layerStates.CLOSED;
            break;
          case layerStates.MIDDLE:
            this.scrollToRetailer = false;
            this.resultsVisible = false;
            this.resultsFixed = true;

            setTimeout(() => {
              this.resultsVisible = true;
              this.resultsFixed = false;

              this.overlayState = layerStates.FULL;
            }, 1);

            break;
          case layerStates.CLOSED:
            this.overlayState = layerStates.MIDDLE;
            break;
        }
      } else {
        this.resultsFixed = true;
        switch (this.overlayState) {
          case layerStates.FULL:
            this.overlayState = layerStates.CLOSED;
            break;
          case layerStates.MIDDLE:
            this.overlayState = layerStates.FULL;
            break;
          case layerStates.CLOSED:
            this.overlayState = layerStates.FULL;
            break;
        }
      }
    },
    selectRetailer(identifier) {
      this.resultsFixed = true;
      this.scrollToRetailer = identifier;
      this.showShowrooms = false;

      if (this.isMobile) {
        this.overlayState = layerStates.MIDDLE;
        if (typeof window !== 'undefined') {
          setTimeout(() => {
            //this.resultsFixed = false;
            window.scrollTo(0, 0);
          }, 10);
        }
      } else {
        this.overlayState = layerStates.FULL;
      }
    },
    scrollToTop() {
      if (typeof window !== 'undefined') {
        window.scrollTo(0, 0);
      }
    },
    handleTouchStart() {
      this.touchStart = true;
    },
    handleTouchMove() {
      clearTimeout(this.touchFinished);
      this.touchFinished = setTimeout(this.resetTouch, 2000);
    },
    resetTouch() {
      this.touchStart = false;
      this.touchMove = false;
    },
    handleMapResize() {
      clearTimeout(this.resizedFinished);

      if (!this.touchStart) {
        this.resizedFinished = setTimeout(this.handleFinishedResize, 100);
      }
      this.touchFinished = null;
      // layerStates.MIDDLE is not allowed for non-mobile view
      if (!this.isMobile && this.overlayStyleClass === layerStates.MIDDLE) {
        this.overlayState = layerStates.FULL;
      }
      if (this.isMobile) {
        this.overlayState = layerStates.MIDDLE;
      }

      this.isMobile = this.checkIfIsMobile();
    },
    handleFinishedResize() {
      // there must not be a layerStates.MIDDLE on non-mobile viewports
      if (this.isMobile) {
        this.overlayState = layerStates.MIDDLE;
      } else {
        // there must not be a layerStates.MIDDLE on non-mobile viewports
        this.overlayState = layerStates.FULL;
      }
    },
    consentHandler(event) {
      this.mapsConsent = event.detail['Google Maps'];
    },

    toggleGoogleMaps() {
      if (typeof window !== 'undefined') {
        if (window.UC_UI && this.getUserCentricsGoogleMapsServiceId) {
          if (!this.mapsConsent) {
            this.checked = false;
          }

          this.checked = !this.checked;

          if (this.checked) {
            window.UC_UI.acceptService(this.getUserCentricsGoogleMapsServiceId);
            setTimeout(() => {
              this.mapsConsent = true;
            }, 500);
          }
        }
      }
    },
  },

  created() {
    if (this.fields?.data) {
      this.initialShowrooms = this.fields.data.showrooms?.children;
      this.initialCountries = this.fields.data.countries?.children;

      if (debug) {
        console.log('initialRetailers', this.fields.data.retailer?.results?.items);
      }
      this.initialRetailers = this.fields.data.retailer?.results?.items;
      this.googleMapsApiKey = this.fields.data.googleMaps?.apiKey?.value;

      this.setCountries(this.initialCountries);
      this.setShowrooms(this.initialShowrooms);
      this.setGoogleMapsApiKey(this.googleMapsApiKey);
    }
    if (!this.isEditing) {
      this.resolvedQuery = resolveQuery(
        queryRetailers,
        /\$contextSiteRootPath/g,
        this.sitecoreContext?.site?.rootPath
      );
    }
    this.isMobile = this.checkIfIsMobile();
  },
  async mounted() {
    // check for the user consent
    // this code will check for the consent if we came from any other SPA page ...
    if (typeof window !== 'undefined' && window.UC_UI) {
      if (debug) {
        console.log('UC - window.UC_UI true ');
      }
      const service = window.UC_UI.getServicesBaseInfo().find(
        service => service.id === this.getUserCentricsGoogleMapsServiceId
      );
      if (service) {
        if (debug) {
          console.log('UC - service available');
        }
        this.mapsConsent = service.consent?.status;
      }
    }

    if (debug) {
      console.log('UC - eventlistener set');
    }

    if (typeof window !== 'undefined') {
      // ... and this code will be triggered on SSR because does window.UC_UI not exist yet, but
      // UC will triggering this event on load
      window.addEventListener('onConsentStatusChange', this.consentHandler);
      // resizing ResultContainer
      window.addEventListener('resize', this.handleMapResize);
      window.addEventListener('orientationchange', this.handleMapResize);

      window.addEventListener('touchstart', this.handleTouchStart, { passive: true });
      window.addEventListener('touchmove', this.handleTouchMove, { passive: true });
    }
    this.retailersNearLocation = this.initialRetailers;

    smoothscroll.polyfill();

    // there must not be a layerStates.MIDDLE on non-mobile viewports
    if (!this.isMobile && this.overlayState === layerStates.MIDDLE) {
      this.overlayState = layerStates.FULL;
    }

    this.$watch(
      'mapsConsentStatus',
      mapsConsentStatus => {
        if (mapsConsentStatus === true) {
          if (debug) {
            console.log('mapsConsentStatus true');
          }
          this.setSearchLocationVisibility(true);
        }

        if (
          typeof window !== 'undefined' &&
          (window.location?.hostname === 'localhost' ||
            window.location?.hostname === 'cw.nolte92.sc' ||
            window.location?.hostname.startsWith('192'))
        ) {
          this.setSearchLocationVisibility(true);
          this.mapsConsent = true;
        }
      },
      { immediate: true }
    );
    this.$watch(
      'fetchNewData',
      fetchNewData => {
        if (fetchNewData === true) {
          if (debug) {
            console.log('fetchNewData');
          }
          this.fetchRetailers();
          this.showShowrooms = false;
        }
      },
      { immediate: true }
    );
    this.$watch(
      'filteredRetailers',
      filteredRetailers => {
        if (filteredRetailers?.length > 0) {
          if (debug) {
            console.log('filteredRetailers changed');
          }
        }
      },
      { immediate: true }
    );
    this.$watch(
      'location',
      location => {
        if (location) {
          if (debug) {
            console.log('location changed');
          }
        }
      },
      { immediate: true }
    );
  },

  beforeUnmount() {
    this.setSearchLocationVisibility(false);
    if (typeof window !== 'undefined') {
      window.removeEventListener('onConsentStatusChange', this.consentHandler);
      window.removeEventListener('resize', this.handleMapResize);
      window.removeEventListener('orientationchange', this.handleMapResize);
      window.removeEventListener('touchstart', this.handleTouchStart);
      window.removeEventListener('touchmove', this.handleTouchMove);
    }
  },
};
</script>

<style lang="scss" scoped>
$transition-timing: 0.5s;

.editorial-map {
  position: relative;
  width: 100%;
  @include fullHeightWithoutHeader();
  margin-bottom: -70px;

  @include helper__until($bp-1024) {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-bottom: -30px;
  }
}

.editorial-map__results {
  @include helper__composited-layer;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;

  width: 100%;

  @include helper__until($bp-1024) {
    position: fixed;
    z-index: 30;
    bottom: 0;
  }
  @include helper__greater($bp-1024) {
    height: 100%;
    width: 50px;
    .full & {
      width: 520px;
    }
  }

  @include helper__until($bp-1024) {
    .middle & {
      //height: 230px;
      height: calc(100vh - 50%);
      height: calc(40vh);

      @include helper__until($bp-768) {
        @media (orientation: landscape) {
          //height: calc(100vh - 200px);
          height: calc(40vh);
        }
      }
      &.fixed {
        position: fixed;
        bottom: 0;
        height: 230px;
      }
    }
    .full & {
      //height: calc(100vh - 230px);
      height: calc(70vh);

      // @include helper__until($bp-768) {
      //   height: calc(100vh - 250px);
      // }
      &.fixed {
        position: fixed;
        bottom: 0;
      }
    }
  }

  @include helper__greater($bp-1024) {
    margin-top: 0;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
}

.editorial-map__results__opener {
  z-index: 20;
  @include helper__transition((opacity), $transition-timing, ease-out);
  box-shadow: 3px 0px 10px rgba(0, 0, 0, 0.1);
  background-color: $color-concret;

  @include helper__until($bp-1024) {
    height: $unit-base;
    width: 100%;
    .full &,
    .middle & {
      box-shadow: none;
      height: 0;
    }
  }
  @include helper__greater($bp-1024) {
    @include fullHeightWithoutHeader(true);

    display: flex;
    height: 100%;
    width: 31px;
    opacity: 1;
    .middle &,
    .full & {
      width: 0px;
    }
  }
}

.editorial-map__results__content {
  @include helper__transition((opacity), $transition-timing, ease-out);
  overflow-y: auto;
  overflow-x: hidden;

  background-color: $color-concret;
  display: none;

  z-index: 20;
  height: 100%;
  width: 0;
  transform: translateX(0);

  box-shadow: 0px -5px 10px rgba(0, 0, 0, 0.1);

  @include helper__greater($bp-1024) {
    box-shadow: 5px 0px 10px rgba(0, 0, 0, 0.1);
  }

  .middle & {
    display: flex;
    pointer-events: auto;
    width: 100%;

    @include helper__greater($bp-1024) {
      margin-top: 0;
      width: 480px;
    }
  }

  .full & {
    display: flex;
    pointer-events: auto;
    width: 100%;

    @include helper__greater($bp-1024) {
      margin-top: 0;
      width: 480px;
    }
  }
}
/* hide scrollbar but allow scrolling */
div.editorial-map__results__content {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
}

div.editorial-map__results__content::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.editorial-map-results-wrapper {
  width: 100%;
  position: relative;
  @include helper__until($bp-1024) {
    //height: calc(100vh - 250px);
    height: calc(70vh);
  }
}

.editorial-map-results-intro {
  @include spacing;
  margin-top: $unit-double !important;
  margin-bottom: $unit-base !important;
  * {
    color: #707070 !important;
  }
}

.result__headline {
  font-family: $font-graphit-bold;
  font-weight: bold;
  font-size: 29px;
  line-height: (36 / 29); // [Intended lineheight] / [font size]
  margin: 0 0 $unit-half;

  @include helper__greater($bp-1024) {
    font-size: 32px;
    line-height: (39 / 32); // [Intended lineheight] / [font size]
  }
}

.result__copy {
  @include text-base;
  margin: 0 $unit-double 0 0;
}

.editorial-map__button-wrapper {
  cursor: pointer;

  padding: 0px 8px 0;
  background-color: $color-concret;
  z-index: 20;

  &.aside {
    display: flex;

    padding: 10px 2px 10px 0px;
    margin-left: -1px;
    margin-top: 22px;
    border-radius: 0 50px 50px 0;
    box-shadow: 4px 0px 6px 0 rgba(0, 0, 0, 0.1);

    @include helper__until($bp-1024) {
      display: none;
    }
  }
  &.top {
    @include helper__greater($bp-1024) {
      display: none;
    }
    @include helper__until($bp-1024) {
      position: relative;
      top: 2px;
      padding: 0px 8px 0;
      border-radius: 50px 50px 0 0;
      box-shadow: 0px -6px 12px 0px rgba(0, 0, 0, 0.1);
      background-color: $color-concret;

      margin: 0 auto;

      z-index: 20;

      background-image: linear-gradient(
        180deg,
        rgba($color-concret, 0.8) 0%,
        rgba($color-concret, 0) 100%
      );
    }
  }
}

.editorial-map__button-arrow {
  .aside & {
    transform: rotate(180deg);

    .full & {
      transform: rotate(360deg);
    }
  }
  .top & {
    transform: rotate(90deg);
    margin-top: 3px;
    margin-bottom: -3px;

    .full & {
      transform: rotate(270deg) !important;
    }
  }

  color: $color-ironside-gray;
}
.editorial-map__searchlocation {
  @include helper__greater($bp-1024) {
    display: none;
  }
  @include helper__until($bp-1024) {
    z-index: 40;
  }
  top: 0;
  display: flex;
  flex: 1 auto;
  width: 100%;
  justify-content: center;
  align-items: flex-end;
  align-content: flex-start;

  :deep {
    .autocomplete-input-container {
      width: 100%;
    }
    .global-map-searchlocation__button {
      width: 74px;
    }
  }
}

:deep #map {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: $color-athens-gray;
}

.editorial-map__no-map {
  @include spacing;
  @include text-intro;
}

.editorial-map__no-map-content {
  display: flex;
  flex-flow: row;
}

.editorial-map__no-map-icon {
  margin-right: $unit-base;
  max-width: max-content;
  overflow: visible;
  flex: 0 0 auto;
}

.editorial-map__no-map-text {
  padding: $unit-half 0;
}

.editorial-map__checkbox {
  @include helper__visually-hidden;
}

.editorial-map__checkbox + label {
  padding-left: $unit-half;
  cursor: pointer;
  position: relative;
  display: inline-flex;
  align-items: center;
  color: $color-ironside-gray;
  user-select: none;
}

.editorial-map__checkbox + label::before {
  @include helper__transition((background-color, border-color));
  content: '';
  width: 26px;
  height: 26px;
  border-radius: 3px;
  border: 2px solid $color-athens-gray;
  background-color: $color-white;
  margin-right: 29px;
  margin-bottom: 3px;
}

.editorial-map__checkbox + label:hover::before {
  border-color: $color-tuatara;
}

// Style when focus by keyboard interaction
body[modality='keyboard'] .editorial-map__checkbox:focus + label::before {
  border-color: $color-azure;
}

.editorial-map__checkbox:checked + label::before {
  background-color: $color-aureolin;
  border-color: $color-citrine;
}
.editorial-map__checkbox + label::after {
  @include helper__transition((opacity, transform));
  @include helper__composited-layer;
  content: '';
  height: 5px;
  width: 9px;
  border-left: 2px solid;
  border-bottom: 2px solid;
  transform: rotate(-45deg) scale(0.7);
  left: 17px;
  top: 20px;
  position: absolute;
  color: $color-tuatara;
  opacity: 0;
}

.editorial-map__checkbox:checked + label::after {
  opacity: 1;
  transform: rotate(-45deg) scale(1);
}
</style>

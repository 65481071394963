<template>
  <div class="editorial-mnk-retailer-selection">
    <EditorialHeadline
      v-if="textHasValue(fields['Headline'])"
      :fields="{ headline: fields['Headline'] }"
      class="headline"
      headline-level="h2"
    />

    <div class="spacing">
      <div class="searchbar">
        <div class="searchbar__q">
          <NolteFormInput
            type="text"
            name="q"
            v-model="model.q"
            autocomplete="off"
            :placeholder="zipCityPlaceholder"
            @keyup.enter="search"
          />
          <button class="searchbar__q__search" @click="search">
            <IconInputSearch />
          </button>
        </div>
        <div class="searchbar__country">
          <NolteFormDropdown
            v-if="countryOptions"
            :options="countryOptions"
            :value="model.country"
            v-model="model.country"
            :clearable="false"
          />
        </div>
      </div>

      <div class="no-retailers" v-if="retailers === null"></div>
      <div class="no-retailers" v-else-if="retailers.length === 0">
        <sc-text
          v-if="textHasValue(fields['No Results Headline'])"
          :field="fields['No Results Headline']"
          tag="p"
          class="subline"
        />

        <EditorialRichtext
          v-if="textHasValue(fields['No Results Copy'])"
          :fields="{ content: fields['No Results Copy'] }"
        />
      </div>

      <ul class="retailers" v-else>
        <li class="retailer" v-for="retailer in retailers" :key="retailer.Id">
          <div class="retailer-checkbox">
            <NolteFormRadio
              :id="`retailer-${retailer.Id.slice(1, -1)}`"
              :code="retailer.Id"
              name="retailers"
              v-model="model.retailer"
            />
          </div>

          <div class="retailer-address">
            <div class="retailer-name">
              <label :for="`retailer-${retailer.Id.slice(1, -1)}`">{{ retailer.Name }}</label>
            </div>

            <div>{{ retailer.Street }}</div>
            <div>{{ retailer.Zip }} {{ retailer.City }}</div>
            <div v-if="retailer.Phone">{{ retailer.Phone }}</div>

            <div class="retailer-distance">
              <a :href="createMapsLink(retailer)" target="_blank">
                {{ retailer.Distance }} {{ distanceUnit }}
              </a>
            </div>
          </div>
        </li>
      </ul>

      <div class="buttons">
        <div class="buttons__first">
          <div
            class="editorial-button-wrapper"
            v-if="textHasValue(fields['Load More Button Text']) && !buttonLoadMoreHidden"
          >
            <NolteButton type="yellow--plain" @click="searchMore">
              {{ fields['Load More Button Text'].value }}
            </NolteButton>
          </div>
        </div>

        <div class="buttons__last">
          <EditorialButton v-if="linkHasValue(fields['Back Button'])" :fields="buttonBack" />

          <EditorialButton v-if="isEditing" :fields="buttonNext" />
          <div class="editorial-button-wrapper" v-else-if="linkHasValue(fields['Next Button'])">
            <NolteButton
              type="yellow--plain"
              @click="pushRetailerToSession(model.retailer)"
              :disabled="buttonNextDisabled"
            >
              {{ fields['Next Button'].value.text }}
            </NolteButton>
          </div>
        </div>
      </div>
    </div>

    <ContentLightbox
      @open="setSetRetailerFailedLightboxVisible(true)"
      @close="setSetRetailerFailedLightboxVisible(false)"
      :visible="setRetailerFailedLightboxVisible"
    >
      <NolteHeadline v-if="textHasValue(fields['Set Retailer Failed Headline'])">
        {{ fields['Set Retailer Failed Headline'].value }}
      </NolteHeadline>

      <p
        v-if="textHasValue(fields['Set Retailer Failed Copy'])"
        v-html="fields['Set Retailer Failed Copy'].value"
      />

      <NolteButton
        v-if="textHasValue(fields['Set Retailer Failed Button Text'])"
        type="yellow--plain"
        @click="setSetRetailerFailedLightboxVisible(false)"
        :preventDefault="true"
      >
        {{ fields['Set Retailer Failed Button Text'].value }}
      </NolteButton>
    </ContentLightbox>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import { dataFetcher } from '@/dataFetcher';
import { textHasValue, linkHasValue } from './helper';
import { isProxy, toRaw } from 'vue';

import { Text } from '@sitecore-jss/sitecore-jss-vue';
import NolteFormInput from '@/nolte-ui/NolteForm/NolteFormInput';
import NolteFormRadio from '@/nolte-ui/NolteForm/NolteFormRadio';
import NolteFormDropdown from '@/nolte-ui/NolteForm/NolteFormDropdown';
import NolteButton from '@/nolte-ui/NolteButton';
import NolteHeadline from '@/nolte-ui/NolteHeadline';
import ContentLightbox from '@/components/ContentLightbox';
import EditorialHeadline from '@/components/EditorialHeadline';
import EditorialButton from '@/components/EditorialButton';
import EditorialRichtext from '@/components/EditorialRichText';
import IconInputSearch from '@/assets/icons/IconInputSearch.svg?inline';

export default {
  name: 'EditorialMNKRetailerSelection',

  components: {
    ScText: Text,
    NolteFormInput,
    NolteFormRadio,
    NolteFormDropdown,
    NolteButton,
    NolteHeadline,
    ContentLightbox,
    EditorialHeadline,
    EditorialButton,
    EditorialRichtext,
    IconInputSearch,
  },

  emits: ['click'],

  data: () => ({
    dealerFinderOptions: null,
    retailers: null,
    totalResults: null,

    setRetailerFailedLightboxVisible: false,

    model: {
      q: '',
      country: null,
      zip: '',
      retailer: null,
      from: 0,
    },
  }),

  methods: {
    textHasValue,
    linkHasValue,
    ...mapActions(['checkUser']),

    buttonParams(type) {
      return {
        ButtonType: {
          fields: {
            Type: {
              value: type,
            },
          },
        },
      };
    },

    setSetRetailerFailedLightboxVisible(status) {
      this.setRetailerFailedLightboxVisible = status;
    },

    search() {
      // reset values
      this.retailers = null;
      this.model.from = 0;
      this.model.retailer = null;

      this.getRetailers().then(result => {
        this.retailers = result.data?.Item1;
        this.totalResults = result.data?.Item2;
        this.model.from = result.data?.Item3;
      });
    },

    searchMore() {
      this.getRetailers().then(result => {
        result.data?.Item1.forEach(item => {
          this.retailers.push(item);
        });
        this.totalResults = result.data?.Item2;
        this.model.from = result.data?.Item3;
      });
    },

    getRetailers() {
      if (isProxy(this.model.country)) {
        this.model.country = toRaw(this.model.country)?.value;
      }
      return this.api('/RetailerRequest/GetRetailers', {
        defaultRadiusGuid: `{${this.fields['Default Radius Selection'].id}}`,
        countryGuid: this.model.country,
        from: this.model.from,
        batchSize: 3,
        zipCity: this.model.q,
      });
    },

    pushRetailerToSession(retailerId) {
      this.api(`/RetailerRequest/SetRetailer/${retailerId}`, {})
        .then(() => {
          this.$router.push(this.fields['Next Button'].value.href);
        })
        .catch(() => {
          this.setSetRetailerFailedLightboxVisible(true);
        });
    },

    createMapsLink(retailer) {
      const query = `${retailer.Name}, ${retailer.Street}, ${retailer.Zip}, ${retailer.City}`;
      return `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(query)}`;
    },

    api(node, data) {
      return dataFetcher(process.env.VUE_APP_API_BASE_URL + node, data).then(response => {
        if (response.status !== 200) {
          throw new Error('No HTTP 200');
        }
        return response;
      });
    },

    getDealerFinderOptions() {
      return this.api('/RetailerSearch/Options', { lang: this.sitecoreContext.language });
    },

    allDataFetched([options, user]) {
      this.dealerFinderOptions = options.data;

      // if the user country is set, start a search
      if (user.country && this.countryOptions) {
        const userCountry = this.countryOptions.find(lang => {
          return lang.acronym === user.country;
        });
        if (userCountry) {
          this.model.country = userCountry.value;
          this.model.q = user.postalCode;

          if (user.postalCode === '') {
            this.model.q = user.city;
          }

          // load the first results for the user
          this.search();
        }
      }
    },
  },

  mounted() {
    // we have to wait for the data of the country selector and the user data
    // then we are able to initialize the rest
    Promise.all([this.getDealerFinderOptions(), this.checkUser()]).then(this.allDataFetched);
  },

  computed: {
    ...mapState('jss', ['sitecoreContext']),
    ...mapGetters(['isMobile']),
    ...mapGetters('jss', ['isEditing']),

    zipCityPlaceholder() {
      if (this.textHasValue(this.fields['ZipCity Placeholder'])) {
        return this.fields['ZipCity Placeholder'].value;
      }
      return '';
    },

    distanceUnit() {
      return this.fields['Default Radius Selection']?.fields?.Unit?.value;
    },

    countryOptions() {
      if (this.dealerFinderOptions === null) return null;

      return this.dealerFinderOptions.CountryOptions.map(country => {
        return {
          label: country.Name,
          value: country.Id?.toLowerCase(),
          acronym: country.Acronym,
        };
      }).sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
    },

    buttonBack() {
      return {
        link: this.fields['Back Button'],
        _extendedParams: this.buttonParams('grey--plain'),
      };
    },

    buttonNext() {
      return {
        link: this.fields['Next Button'],
        _extendedParams: this.buttonParams('yellow--plain'),
      };
    },

    buttonLoadMoreHidden() {
      return this.totalResults <= this.model.from;
    },

    buttonNextDisabled() {
      return this.model.retailer === null;
    },
  },
};
</script>

<style lang="scss" scoped>
.editorial-mnk-retailer-selection {
  background: $color-white;
  overflow: initial;

  // triggers the complete rendering of the background
  padding-top: 1px;
}

.spacing {
  @include spacing;

  .editorial-headline-wrapper {
    padding: 0;
  }
  .editorial-rich-text-wrapper {
    padding: 0;
    margin: $unit-double 0;
  }
  .subline {
    @include text-intro;
  }
  :deep(.headline--level3) {
    text-transform: none;
    letter-spacing: 0;
  }
}

.headline {
  :deep() > * {
    @include helper__greater($bp-768) {
      max-width: none;
      font-size: 36px;
      line-height: (48 / 36);
    }
  }
}

.searchbar {
  display: flex;
  margin: $unit-double 0;
  flex-direction: column;

  .searchbar__q {
    order: 2;
    position: relative;
    margin: $unit-double 0 0;

    :deep(input) {
      @include text-copy;
      padding: 0 $unit-double * 2 $unit-half $unit-half;

      &::-ms-clear {
        display: none;
      }
    }
  }
  .searchbar__q__search {
    position: absolute;
    right: $unit-base;
    top: 0;
    border: 0;
    padding-top: 0;
  }
  .searchbar__country {
    order: 1;
  }

  @include helper__greater($bp-768) {
    flex-direction: row;
    align-items: flex-end;

    .searchbar__q {
      order: 1;
      width: 66.666%;
      margin: 0;
      padding: 0 $unit-double 0 0;
    }
    .searchbar__q__search {
      right: $unit-double;
    }
    .searchbar__country {
      order: 2;
      width: 33.333%;
    }
  }
}

.retailers {
  .retailer {
    display: flex;
    margin: $unit-base 0;
    padding: 0 $unit-base 0 0;
  }

  .retailer-checkbox {
    margin: 0 $unit-half 0 0;
  }

  .retailer-name {
    @include text-h4;
    margin: 11px 0 $unit-half;

    label {
      cursor: pointer;
    }
  }

  .retailer-distance {
    @include text-h4;
    margin: $unit-half 0;

    a {
      border-bottom: 2px solid $color-ironside-gray;
    }
  }

  @include helper__greater($bp-768) {
    display: flex;
    flex-wrap: wrap;

    .retailer {
      flex-grow: 0;
      flex-shrink: 0;
      width: 50%;
    }
  }

  @include helper__greater($bp-1024) {
    .retailer {
      width: 33.333%;
    }
  }
}

.buttons {
  margin: $unit-double 0;

  :deep() {
    .editorial-button-wrapper {
      margin: $unit-base 0;
    }
    .editorial-button {
      @include text-h5;
    }
  }

  .buttons__first {
    :deep(.editorial-button-wrapper) {
      margin-bottom: 0;
    }
  }

  .buttons__last {
    display: flex;

    > * {
      width: 100%;
    }
    :deep(.editorial-button-wrapper:first-child) {
      padding: 0 $unit-base 0 0;
    }
  }

  @include helper__until($bp-768) {
    width: 100%;
    flex: 0 0 50%;

    > * {
      width: 100%;
    }

    :deep(.editorial-button-wrapper) {
      padding: 0;
    }

    :deep(.editorial-button),
    :deep(.nolte-button) {
      min-width: 0;
      max-width: none;
      width: 100%;
      display: block;
    }
  }

  @include helper__greater($bp-768) {
    display: flex;
    justify-content: space-between;
  }
}
</style>
